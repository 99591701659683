import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useMachine } from '@xstate/react';
import { darken, transparentize } from 'polished';
import { authMachine, AuthMachineContext } from './machines/authMachine';
import { Font, Color } from './Style';

import { Hero } from './components/Hero';
import { User } from './components/User';
import { TapCap } from './components/TapCap';

const Styled = {
  Status: styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    top: 0;
    right: 0;
    padding: 32px 32px 0 0;
    max-height: 100%;
    box-sizing: border-box;
    z-index: 2;
    color: #fff;
  `,
  Hero: styled.div`
    padding: 100px 0 64px 0;
    text-align: center;
    color: ${Color.white};

    user-select: none;

    h1 {
      margin: 0 0 24px 0;
      font-size: 3.5em;

      small {
        position: absolute;
        margin: -1px 0 0 -26px;
        padding: 1px 3px;
        font-size: 0.18em;
        font-family: ${Font.text};
        background-color: ${Color.primary};
        border-radius: 2px;
        color: ${Color.white};
      }
    }

    p {
      margin: 0 0 16px 0;
      color: ${transparentize(0.2, Color.white)};
    }

    small {
      font-size: 0.7em;
      color: ${transparentize(0.5, Color.white)};

      b {
        color: ${transparentize(0.4, Color.white)};
      }
    }
  `,
  Background: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('/background.jpg');
    opacity: 0.5;
    z-index: -1;

    filter: blur(4px);
  `,
  List: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -16px auto 64px auto;
    width: 100%;

    > a {
      display: block;
      margin: 0 16px;
      text-decoration: none;
      user-select: none;

      > div {
        padding: 32px;
        border-radius: 4px;
        box-sizing: border-box;
        color: white;
      }

      h1 {
        margin: 0;
        color: #fff;
        opacity: 1;
      }

      p {
        opacity: 0.75;
      }

      a {
        font-weight: bold;
        color: ${Color.primary};
      }
    }
  `,
  Market: styled.div`
    background-color: #323643;

    transition: transform 200ms ease;

    &:hover {
      transform: translateY(-2px);
    }
  `,
};

export const App: React.FC = () => {
  const [currentAuthMachine, sendToAuthMachine] = useMachine(authMachine);

  const { user } = currentAuthMachine.context;

  return (
    <AuthMachineContext.Provider
      value={[currentAuthMachine, sendToAuthMachine]}
    >
      <Styled.Background />
      {user && (
        <Styled.Status>
          <User
            user={user}
            subtext={
              user._type === 0
                ? 'Not authenticated with Discord ❌'
                : 'Authenticated ✔️'
            }
            link={user._type === 0}
          />
        </Styled.Status>
      )}
      <Styled.Hero>
        <h1>
          <small>TAP</small>Industries
        </h1>
        {/*
        <p>Access all of TAP</p>
        <small>asd</small>
        */}
      </Styled.Hero>
      <Styled.List>
        <a href="https://market.tap.industries/">
          <Styled.Market>
            <h1>🛒 Market</h1>
            <p>Preview 🔍 and manage 🎛️ filters for the TAP Discord bot</p>
            <a>market.tap.industries</a>
          </Styled.Market>
        </a>
        <a href="https://soundboard.tap.industries/">
          <Styled.Market>
            <h1>🎛️ Soundboard</h1>
            <p>Ye olde soundboard with screams 🗣️ from the past</p>
            <a>soundboard.tap.industries</a>
          </Styled.Market>
        </a>
      </Styled.List>
      <TapCap />
    </AuthMachineContext.Provider>
  );
};
