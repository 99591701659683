import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { IAPIUser } from '../machines/authMachine';
import { getFromAPI, postToAPI } from '../lib/api';
import { Font, Color } from '../Style';
import faker from 'faker';
import * as Three from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import LinkVariantPlusIcon from 'mdi-react/LinkVariantPlusIcon';

const classicColor = '#E6D393';

const reviewTextList = [
  `Classic Baseball Hat is the next killer app. I could probably go into sales for you. You won't regret it`,
  `It's really wonderful. Classic Baseball Hat is worth much more than I paid. Classic Baseball Hat is both attractive and highly adaptable.`,
  `It's incredible. Classic Baseball Hat was worth a fortune to my company.`,
  `I love your system. The service was excellent. I can't say enough about Classic Baseball Hat. Absolutely wonderful!`,
  `Buy this now. Classic Baseball Hat impressed me on multiple levels. Classic Baseball Hat is exactly what our business has been lacking.`,
  `We've seen amazing results already. Definitely worth the investment. I don't always clop, but when I do, it's because of Classic Baseball Hat. Thanks for the great service.`,
  `Classic Baseball Hat is the most valuable business resource we have EVER purchased. It's the perfect solution for our business. I don't always clop, but when I do, it's because of Classic Baseball Hat.`,
  `I didn't even need training. I was amazed at the quality of Classic Baseball Hat. Classic Baseball Hat is both attractive and highly adaptable. I am really satisfied with my Classic Baseball Hat.`,
  `Classic Baseball Hat has got everything I need.`,
  `I like Classic Baseball Hat more and more each day because it makes my life a lot easier. Classic Baseball Hat is awesome! Really good.`,
  `I have gotten at least 50 times the value from Classic Baseball Hat.`,
  `Nice work on your Classic Baseball Hat. I couldn't have asked for more than this.`,
];

const reviewImageList = [
  '/review/user_01.png',
  '/review/user_02.png',
  '/review/user_03.png',
  '/review/user_04.png',
  '/review/user_05.png',
  '/review/user_06.png',
  '/review/user_07.png',
];

const generateReviewList = () => {
  const list = [];
  for (let i = 0; i < 4; i++) {
    list.push({
      text: reviewTextList.splice(
        Math.floor(Math.random() * reviewTextList.length),
        1
      ),
      image: reviewImageList.splice(
        Math.floor(Math.random() * reviewImageList.length),
        1
      ),
      username: faker.internet.userName(),
    });
  }
  return list;
};

const Styled = {
  Container: styled.div``,
  Classics: styled.div`
    display: block;
    width: 300px;
    margin: 0 auto;
    font-family: 'Playfair Display', serif;
    font-size: 5rem;
    text-align: center;
    line-height: 4.8rem;
    font-variant: small-caps;
    color: ${classicColor};

    b {
      display: block;
      font-family: ${Font.text};
      font-size: 1.4rem;
      line-height: initial;
      font-variant: normal;
      border-bottom: 3px solid ${classicColor};
    }

    i {
      display: block;
      margin-top: 0.5rem;
      font-family: ${Font.text};
      font-size: 1.4rem;
      font-weight: bold;
      line-height: 2.4rem;
      font-variant: normal;
      border-top: 3px solid ${classicColor};
      border-bottom: 3px solid ${classicColor};
    }
  `,
  Cap: styled.div`
    position: relative;
    margin: 100px auto 0 auto;
    width: 800px;
    height: 500px;
    /*background-image: url('/tap_cap_compressed.png');*/
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 10;
  `,
  Background: styled.div`
    position: relative;
    margin-top: -250px;
    padding-top: 290px;
    padding-bottom: 64px;
    width: 100%;
    min-height: 500px;
    background-color: #323643;
    z-index: 1;

    > div {
      margin: 0 auto;
      max-width: 500px;
      width: 100%;
    }

    h1 {
      margin-bottom: 3rem;
      text-align: center;
      font-size: 3em;
      font-family: 'Playfair Display', serif;
      color: ${Color.white};

      small {
        position: absolute;
        margin: 14px 0 0 -26px;
        padding: 1px 3px;
        font-size: 0.18em;
        font-family: ${Font.text};
        background-color: ${Color.primary};
        border-radius: 2px;
        color: ${Color.white};
      }
    }

    p {
      line-height: 140%;
      color: ${Color.white};
      opacity: 0.7;
    }

    ul {
      line-height: 180%;
      color: ${Color.white};
      opacity: 0.7;
    }

    h4 {
      margin-top: 4rem;
      font-size: 1.1rem;
      color: ${Color.white};
    }
  `,
  Review: styled.div`
    display: flex;
    margin-bottom: 32px;

    .image {
      flex-shrink: 0;
      margin-right: 14px;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-position: center;
      background-size: 100%;
    }

    h5 {
      margin: 8px 0 0 0;
      font-size: 1em;
      color: ${Color.white};
    }

    p {
      margin: 8px 0 0 0;
      font-size: 0.8rem;
    }
  `,
};

const reviewList = generateReviewList();

export const TapCap: React.FC = () => {
  const capRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (capRef.current) {
      const height = 500;
      const width = 800;

      const camera = new Three.PerspectiveCamera(30, width / height, 0.01, 10);

      camera.position.z = 4.2;

      const scene = new Three.Scene();

      const light = new Three.AmbientLight(0xffffff, 0.4); // soft white light
      scene.add(light);

      const light2 = new Three.PointLight(0xffffff, 1.1, 100);
      light2.position.set(0, 30, 10);
      scene.add(light2);

      const renderer = new Three.WebGLRenderer({
        antialias: true,
        alpha: true,
      });
      renderer.setSize(width, height);
      capRef.current.appendChild(renderer.domElement);

      const loader = new GLTFLoader();
      let model: Three.Group | null = null;
      loader.load(
        '/tappikappi3.glb',
        (gltf) => {
          model = gltf.scene;
          model.rotation.y -= 0.6;
          scene.add(model);
        },
        undefined,
        (error) => {
          console.error(error);
        }
      );

      let time = 0;
      const animate = () => {
        if (model) {
          // model.rotation.y = 24 + Math.sin(time * 0.005) * 1.2;
          model.rotation.y += 0.003;
        }

        time++;

        requestAnimationFrame(animate);
        renderer.render(scene, camera);
      };

      animate();
    }
  }, [capRef]);

  return (
    <Styled.Container>
      <Styled.Classics>
        <b>TAP</b>
        Classics
        <i>PRESENTS</i>
      </Styled.Classics>
      <Styled.Cap>
        <div ref={capRef} />
      </Styled.Cap>
      <Styled.Background>
        <div>
          <h1>
            <small>TAP</small>Classic Baseball Hat
          </h1>
          <p>
            Dad hats aren't just for dads – the TAP Classics Baseball Hat is
            trendy and stylish. It has an unstructured form, a curved visor, and
            an adjustable buckle strap. Comfort + style = win/win!
          </p>
          <ul>
            <li>100% chino cotton twill</li>
            <li>Unstructured, 6-panel, low-profile</li>
            <li>6 embroidered eyelets</li>
            <li>3 ⅛” crown</li>
            <li>Adjustable strap with antique buckle</li>
            <li>Head circumference: 20 ½” - 21 ⅝”</li>
          </ul>

          <h4>See what others are saying</h4>

          {reviewList.map((review) => (
            <Styled.Review>
              <div
                className="image"
                style={{ backgroundImage: `url(${review.image})` }}
              />
              <div>
                <h5>{review.username}</h5>
                <p>{review.text}</p>
              </div>
            </Styled.Review>
          ))}
        </div>
      </Styled.Background>
    </Styled.Container>
  );
};
