import React from 'react';
import styled, { css } from 'styled-components';
import { IAPIUser } from '../machines/authMachine';
import { getFromAPI, postToAPI } from '../lib/api';

import LinkVariantPlusIcon from 'mdi-react/LinkVariantPlusIcon';

const Styled = {
  Card: styled.div<{ small: boolean }>`
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    height: 46px;
    min-width: 200px;

    animation: expand 1s 1 cubic-bezier(0.16, 1, 0.3, 1);

    ${(props) =>
      props.small &&
      css`
        height: 28px;

        span > h4 {
          font-size: 0.9em;
        }

        span > small {
          display: none;
        }
      `}

    > span {
      display: block;
      margin: 0 14px 0 0;
    }

    @keyframes expand {
      from {
        height: 0px;
      }
      to {
        transform: 46px;
      }
    }

    @keyframes intro {
      from {
        transform: translateY(-100%);
      }
      to {
        transform: translateY(0);
      }
    }

    @keyframes hold {
      from {
        transform: translateY(-100%);
      }
      to {
        transform: translateY(-100%);
      }
    }

    h4 {
      margin: 0;
      font-size: 1em;
      text-align: right;
      font-family: 'Roboto';
      overflow: hidden;

      > span {
        display: block;
        transform: translateY(0);

        animation: hold 1s 1, intro 1s 1s 1 cubic-bezier(0.16, 1, 0.3, 1);
      }
    }

    small {
      display: block;
      margin-top: 4px;
      font-weight: normal;
      font-size: 0.7em;
      text-align: right;
      opacity: 0.5;
      overflow: hidden;

      > span {
        display: block;
        transform: translateY(0);

        animation: hold 1.25s 1, intro 1s 1.25s 1 cubic-bezier(0.16, 1, 0.3, 1);
      }
    }
  `,
  Image: styled.div<{ src?: string | null; small: boolean }>`
    width: 46px;
    height: 46px;
    background-color: rgba(255, 255, 255, 0.25);
    border-radius: 50%;
    text-align: center;
    line-height: 46px;
    font-size: 1.1em;

    animation: backHold 0.25s 1, back 1.5s 0.25s ease-out;

    ${(props) =>
      props.small &&
      css`
        width: 28px;
        height: 28px;
        line-height: 28px;
        font-size: 0.8em;
      `}

    @keyframes appear {
      from {
        transform: scale(0) rotate(-45deg);
      }
      to {
        transform: scale(1) rotate(0);
      }
    }

    @keyframes appearHold {
      from {
        transform: scale(0);
      }
      to {
        transform: scale(0);
      }
    }

    @keyframes back {
      from {
        background-color: rgba(255, 255, 255, 0);
        ${(props) =>
          props.src &&
          css`
            opacity: 0;
          `}
      }
      to {
        background-color: rgba(255, 255, 255, 0.25);
        ${(props) =>
          props.src &&
          css`
            opacity: 1;
          `}
      }
    }

    @keyframes backHold {
      from {
        background-color: rgba(255, 255, 255, 0);
        ${(props) =>
          props.src &&
          css`
            opacity: 0;
          `}
      }
      to {
        background-color: rgba(255, 255, 255, 0);
        ${(props) =>
          props.src &&
          css`
            opacity: 0;
          `}
      }
    }

    > span {
      display: block;
      animation: appearHold 0.25s 1,
        appear 0.5s 0.25s cubic-bezier(0.34, 1.56, 0.64, 1);
    }

    ${(props) =>
      props.src &&
      css`
        background-image: url(${props.src});
        background-position: center;
        background-size: cover;

        > span {
          display: none;
        }
      `}
  `,
  Button: styled.a`
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    bottom: -32px;
    right: 58px;
    padding: 6px 6px 6px 8px;
    font-size: 0.8em;
    user-select: none;
    cursor: pointer;
    opacity: 0.8;
    color: #fff;

    transition: opacity 100ms;

    animation: appearButtonHold 1.5s 1, appearButton 0.5s 1.5s 1;

    @keyframes appearButton {
      from {
        opacity: 0;
      }
      to {
        opacity: 0.8;
      }
    }

    @keyframes appearButtonHold {
      from {
        opacity: 0;
      }
      to {
        opacity: 0;
      }
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.2);
      border-radius: 3px;
      transform: scale(0.8);
      opacity: 0;

      transition: opacity 100ms, transform 100ms;
    }

    &:hover {
      opacity: 1;

      &:after {
        transform: scale(1);
        opacity: 1;
      }
    }

    svg {
      margin: 0 0 0 0.5em;
      width: 18px;
      height: 18px;
    }
  `,
};

export const User: React.FC<{
  user: IAPIUser;
  subtext?: string | React.ReactNode;
  small?: boolean;
  link?: boolean;
}> = ({ user, subtext, small = false, link = false }) => {
  const handleClickLinkAccount = async () => {
    const { url } = await postToAPI('/auth/discord', {});
    window.location.href = url;
  };

  return (
    <Styled.Card small={small}>
      <Styled.Image src={user.image || null} small={small}>
        <span>{user.icon}</span>
      </Styled.Image>
      <span>
        <h4>
          <span>{user.username}</span>
        </h4>
        {subtext && (
          <small>
            <span>{subtext}</span>
          </small>
        )}
      </span>
      {link && user._type === 0 && (
        <Styled.Button onClick={handleClickLinkAccount}>
          Link Discord
          <LinkVariantPlusIcon />
        </Styled.Button>
      )}
    </Styled.Card>
  );
};
