export const Color = {
  primary: '#EB506B',
  black: '#323643',
  dark: '#606470',
  dimgray: '#A9ABB2',
  gray: '#D2D2D4',
  white: '#F7F7F7',
};

export const Font = {
  header: `'Josefin Sans', sans-serif`,
  text: `'Roboto', sans-serif`,
  code: `source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace`,
};
