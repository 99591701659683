import { createContext } from 'react';
import { Machine, State, assign } from 'xstate';
import { postToAPI, getApiUser } from '../lib/api';

export interface IAPIUser {
  _id: number;
  _type: number;
  username: string;
  icon: string;
  image: string | null;
  connectedAt: Date;
}

export interface IAuthMachineContext {
  accessToken: string | null;
  user: IAPIUser | null;
}

export const AuthMachineContext = createContext<
  [
    State<IAuthMachineContext>,
    (event: any, payload?: Partial<IAuthMachineContext>) => any
  ]
>([] as any);

const validateAuthStatus = async () => {
  try {
    const user = await getApiUser();
    if (!user) {
      throw new Error('Invalid session');
    }

    return { user };
  } catch (error) {
    throw error;
  }
};

const createNewAccessToken = async () => {
  console.log('Generating a new access token');

  const { user } = await postToAPI('/auth/create', {});
  console.log('session', user);
  if (!user) throw new Error('Could not authenticate');

  return { user };
};

const waitForSeconds = (seconds: number) =>
  new Promise((resolve) => setTimeout(resolve, seconds * 1000));

export const authMachine = Machine<IAuthMachineContext>({
  id: 'auth',

  context: {
    accessToken: null,
    user: null,
  },

  initial: 'unknown',
  states: {
    unknown: {
      invoke: {
        src: (context, event) => validateAuthStatus(),
        onDone: {
          target: 'authorized',
          actions: assign({
            user: (context, event) => event.data.user,
          }),
        },
        onError: {
          target: 'unauthorized.autorizing',
        },
      },
    },
    unauthorized: {
      initial: 'idle',
      states: {
        idle: {
          invoke: {
            src: (context, event) => waitForSeconds(5),
            onDone: {
              target: 'autorizing',
            },
          },
        },
        autorizing: {
          invoke: {
            src: (context, event) => createNewAccessToken(),
            onDone: {
              target: 'done',
            },
            onError: {
              target: 'idle',
            },
          },
        },
        done: { type: 'final' },
      },
      onDone: {
        target: 'unknown',
      },
    },
    authorized: {
      on: {
        REFRESH: {
          target: 'unknown',
        },
      },
    },
  },
});
